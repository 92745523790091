import { Vue, Component, Prop } from 'vue-property-decorator';
import { ContentCardData } from "@/utilities/cmsUtilities";

@Component({
  name: 'cms-horizontal-card'
})
export default class CmsHorizontalCardComponent extends Vue {

  @Prop({ type: Object })
  cardData!: ContentCardData;

  onClick() {
    this.$router.push(`${this.cardData.redirectUrl}`)
  }

}